.cdr-footer{
  background:$drk-gray;
  color:#fff;
  min-height:70px;
  line-height:70px;
}

.cdr-sitemap{
  font-size:13px;
}
.cdr-sitemap ul, .cdr-sitemap ul li {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cdr-sitemap ul li {
  display: block;
  white-space: nowrap;
  padding: 0px;
  line-height:20px;
  a{
    color: #fff;
    text-decoration:none;
    text-transform:uppercase;
  }
  a:hover{
    color:$orange

  }

}
.cdr-button-2{
  background-color:$orange;
  color:#fff;
}

.cdr-nwsl-form{
  .email-subscribers-2{
    .es_lablebox {
     display:none!important
    }
    .es_textbox_class {
      width: 80%;
      height:36px;
      line-height:36px;
      color:#000;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      border:0;

      float:left;
    }
    .es_textbox_button {
      width: 20%;
      height:36px;
      line-height:36px;
      background-color:$orange;
      color:#fff;
      border:0;
      margin-top:-20px;
      float:left;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}
@media screen and (max-width:768px){
  .cdr-orange-box{
    span{
      font-size:16px!important;
    }

  }
  .cdr-footer{
    p{
      font-size:12px;
    }
  }
}
.cdr-copyright{
  border-top:1px solid #333;
  height: 60px;
  line-height:60px;
  margin-bottom:1px;
}
.cdr-client-copy{
  float:left
}
.cdr-powered-copy{
  float:right;
  background-image:url("../../dist/images/agency-logo-footer.png");
  background-size:contain;
  height:40px;
  width:40px;
  margin-top:10px;
}
.cdr-powered-copy:hover{
  background-image:url("../../dist/images/agency-logo-footer-hover.png");

}