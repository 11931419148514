// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #27ae60;
$gray: #494949;
$drk-gray:  #282828;
$orange: #f59f28;
$drk-orange: #ce8626;
