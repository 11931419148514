/* MAIN CONTAINER */
.cdr-container{
  padding:30px 0;
  min-height:600px;
  //height:450px;
  h2{
    font-weight:800;
    text-transform:uppercase;
    font-size:24px;
    margin:0px 0 40px 0;
  }
  h4{
    font-weight:800;
  }
  p{
    font-weight:300;
    font-size:20px;
  }
  a{
    text-decoration:none;
    color:#000;
  }
}
.cdr-container-docs{
  min-height: 500px;
  padding:30px 0;
  h2{
    font-weight:800;
    text-transform:uppercase;
    font-size:24px;
    margin:0px 0 40px 0;
  }
  h4{
    font-weight:800;
  }
  p{
    font-weight:300;
    font-size:20px;
  }
  a{
    text-decoration:none;
    color:#000;
  }
}
.cdr-icon{
  text-align:center;
  //width:180px;
  height:180px;
  margin-bottom:10px;
}
.cdr-paddings{
  padding:30px 0;
  min-height:600px;
  height:450px;
}
.cdr-table{
  display:table;
  height:100%;
  width:100%;
}
.cdr-cell{
  height:100%;
  width:100%;
  display:table-cell;
  vertical-align:middle;
  text-align:center;
}
.cdr-middle{
  vertical-align:middle;
  height:100%;
  width:100%;
  text-align:left!important;
}
.cdr-quote{
  min-height:280px;
  height:150px;
  background:#fff;
  h2{
    color:#282828;
    font-weight:200;
    font-size:36px;
    float:left;
    text-align:left;
  }
  h5{
    font-size:14px;
    float:right;
  }
  .igorny {
    vertical-align: super;
    color:$orange;
    float:left;
    font-size:36px;
    margin-right:5px;
  }
}
.cdr-navi{
  min-height: 365px;
  background:#fff;
  padding:20px 0;
  h2{
    font-weight:800;
    font-size:24px;
    text-align:center;
  }
  h5{
    font-weight:700;
    font-size:20px;
    text-align:left;
  }
  p{
    font-size:19px;
  }
  .row{
    .col-md-6{
      padding:40px 20px;
    }
  }
}
@media screen and (max-width:768px){
  .cdr-quote{
    min-height:280px;
    height:150px;
    background:#fff;
    h2{
      color:#282828;
      font-weight:200;
      font-size:28px;
      float:left;
      text-align:left;
    }
    h5{
      font-size:14px;
      float:right;
    }
    .igorny {
      vertical-align: super;
      color:$orange;
      float:left;
      font-size:28px;
      margin-right:5px;
    }
  }
}




.cdr-orange-box{
  background-color:$orange;
  height:155px;
  color:#fff;
}
.cdr-orange{
  background-color:$orange;
}
.cdr-gray{
  background-color:#4e5053;
}


/* CONTACT */
.cdr-contact-section{
  a{
    color:#000;
  }
  a:hover{
    color:#000;
    text-decoration:none;
  }
}
.cdr-contact-section input[type=submit] {
  float: right;
  padding: 5px;
  width: 100px;
  background: #db9215;
  color: #fff;
  border: 0;
  a{
    color:#000;
  }
}
.cdr-contact-section textarea {
  width: 100%;
  padding: 5px 10px;
}
.cdr-contact-section input {
  width: 100%;
  padding: 5px 10px;
}

//MAP STYLE
/* MAP */

#google-container {
  position: relative;
  width: 100%;
  height: 200px;
  background-color: #e7eaf0;
}

@media only screen and (min-width: 768px) {
  #google-container {
    height: 300px;
  }

}

@media only screen and (min-width: 1170px) {
  #google-container {
    height: 600px;
  }
}

#cd-google-map {
  position: relative;

}

#cd-google-map address {
  position: absolute;
  width: 40%;
  height: 100%;
  bottom: -20px;
  right: 0;
  padding: 1em 1em;
  background-color: rgba(0, 0, 0, .7);
  color: #fff;
  font-size: 13px;
  font-size: 1.8125rem;
}

@media only screen and (min-width: 768px) {
  #cd-google-map address {
    font-size: 15px;
    font-size: 1.9375rem;
    text-align: center;
  }
}

.cd-controls {
  top: 30px !important
}

#cd-zoom-in, #cd-zoom-out {
  height: 32px;
  width: 32px;
  cursor: pointer;
  margin-left: 10px;
  z-index:9000;
  background-color: rgba(0, 0, 0, 0.9);
  background-repeat: no-repeat;
  background-size: 32px 64px;
  background-image: url("../../dist/images/cd-icon-controller.svg");
}

.no-touch #cd-zoom-in:hover, .no-touch #cd-zoom-out:hover {
  background-color: #333;
  z-index:2000;
}

@media only screen and (min-width: 768px) {
  #cd-zoom-in, #cd-zoom-out {
    margin-left: 50px;
  }
}

#cd-zoom-in {
  background-position: 50% 0;
  margin-top: 10px;
  margin-bottom: 1px;
}

@media only screen and (min-width: 768px) {
  #cd-zoom-in {
    margin-top: 50px;
  }
}

#cd-zoom-out {
  background-position: 50% -32px;
  z-index:2000;
}




/* OFFER PAGE STYLE */
.cdr-offer{
  padding:30px 0;
  h4{
    font-weight:800;
    text-transform:uppercase;
  }
}
.cdr-section-1{
  height:250px;
  min-height:290px;

}
.cdr-section-2{
  height:250px;
  min-height:340px;
  p{
    color:#fff;
    text-align:left;
  }
}
.cdr-section-3{
  height:450px;
  min-height:600px;
  p{
    color:#000;
  }
  .cdr-box{
    min-height:32px;
    height:320px;
    p{
      text-align:left;
    }
  }
}
.cdr-section-4{
  height:500px;
  min-height:600px;
  h4{
    color:#fff
  }
  p{
    color:#fff;
    text-align:left;
  }
  .cdr-box{
    min-height:400px;
    height:400px;
    p{
      text-align:left;
    }
  }
}

@media screen and (max-width:970px){
  .cdr-section-3{
    height:450px;
    min-height:950px!important;
  }
  .cdr-section-4 {
    height: 500px;
    min-height: 1080px!important;
  }
  .cdr-middle {
    vertical-align: middle;
    height: 100%;
    width: 100%;
    text-align: center!important;
  }
}
@media screen and (max-width:460px){
  .cdr-section-3 .cdr-box {
    min-height: 358px!important;
    height: 320px;
  }
  .cdr-section-2 {
    height: 250px;
    min-height: 480px!important;
  }
  .cdr-section-4 {
    height: 500px;
    min-height: 940px!important;
  }
  .cdr-group {
    .cdr-group-image {
      width: 13%!important;
      clear: both;
      float: none !important;

    }
  }


}
@media screen and (max-width:768px){

  .cdr-group{
    height:125px!important;
    text-align:center;
    .cdr-group-image{
      width:10%;
      clear:both;
      float:none!important;

    }
    .cdr-group-text{
      width:100%!important;
      float:none!important;
      .cdr-title{
        font-weight:800;
        font-size:14px;
        margin-left:0px!important;
        margin-top:5px;


      }
      .cdr-span{
        font-size:12px;
        margin-left:0px!important;
        margin-top:-5px;

      }

    }
  }
}
@media screen and (max-width:970px){
  .cdr-group{
    .cdr-group-image{
      width:8%;
    }
    .cdr-group-text{
      width:80%;
      .cdr-title{
        font-weight:800;
        font-size:14px;
        margin-top:15px!important;
      }
      .cdr-span{
        font-size:12px;
        margin-top:-5px;
      }
    }
  }
}

.wpcf7 input {

  padding: 5px 10px;
}
.cdr-field-100 input{
  width:calc(100% - 5px);

}
.cdr-field-33 input{
  width:calc(33.333% - 5px);
  margin-right:5px;
}
.cdr-field-50 input {
  width: calc(50% - 5px);
  margin-right: 5px;
}
.cdr-send input{
  background:$orange;
  float:right;
  width:250px;
  padding:5px 10px;
  color:#fff;
  margin-right:5px;
  border:0;
}

.cdr-send-2{
  background:$orange;
  text-align:center;
  padding:10px 40px;
  color:#fff;
  border:0;
}
.cdr-send-2:hover{
  color:#fff;
  text-decoration:none;
}


/* PODSTRONA TABOR */
.cdr-tabor{
  //min-height:600px;
  .cdr-box{
    height:150px;
    min-height:250px;
  }
  h4{
    font-size:24px;
    color:#000;
    font-weight:800;
    text-transform:uppercase;
    margin-bottom:30px;
  }
  p{
    min-height:130px;
  }
}


/* Galeria */
.cdr-galery-header{
  height:100px;
  h4{
    font-size:24px;
    color:#000;
    font-weight:800;
    text-transform:uppercase;
  }
}
.cdr-vehicle-img{
  text-align:right;
}

.cdr-group{
  height:65px;
  margin-bottom:30px;
    .cdr-group-image{
      width:8%;
      float:left;
    }
    .cdr-group-text{
      width:80%;
      float:left;
      .cdr-title{
        font-weight:800;
        font-size:14px;
        margin-left:20px;
        margin-top:5px;
      }
      .cdr-span{
        font-size:12px;
        margin-left:20px;
        margin-top:-5px;
      }
    }
}
.cdr-slider{
  color:#fff;
  .carousel-indicators{
    margin-bottom:80px;
  }
  .carousel-indicators li{
    border:0;
    background:transparent;
    border-radius:0;
    padding:0!important;
    height:90px!important;
    width:150px!important;
    margin:0 20px;
    text-aling:center;
    opacity:0.5;
  }
  .carousel-indicators .active {
    margin: 0;
    border-radius:0;
    margin:0 20px;
    padding:0!important;
    height:90px!important;
    width:150px!important;
    opacity:1;
    background-color: transparent;
  }
  .carousel-control.right, .carousel-control.left {
    background-image: none!important;

  }
}


/* ALERTS */
div.wpcf7-validation-errors {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
  margin-top: 60px;
}
div.wpcf7-mail-sent-ok {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
  margin-top: 60px;
}

/* Social */
.cdr-circle{
  margin-bottom:10px;
  height:32px;
  width:32px;
  line-height:32px;
  text-align:center;
  font-size:21px;
  color:#fff;
  border-radius:50%;
  background:$orange;
}

@-moz-document url-prefix() {
  .img-responsive {
    width:100%!important;
  }
}


/* SWIPER */
.swiper-container {
  width: 100%;
  height: 100%;
  overflow-x:hidden;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  width:100%;
  //background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.swiper-slide img{
  height:450px;
  margin:30px 0;
}
.swiper-button-next, .swiper-button-prev{
 color:#fff;
}