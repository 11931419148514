//HEADER

.cdr-header{
  background-color:#fff;
  border:0;
  height:120px;
  border-radius:0;
  position:relative;

}

.container{
  position:relative;
}
.cdr-phone{
  border-radius:4px;
  background:$drk-gray;
  height:40px;
  width:200px;
  line-height:40px;
  font-size:16px;
  color:#f5f5f5;
  text-align:center;
  position:absolute;
  top:-3px;
  right:120px
}
.cdr-lang{
  border-radius:4px;
  //background:$drk-gray;
  content:"\A\f0d8"; //carriage return
  height:40px;
  width:40px;
  line-height:40px;
  font-size:18px;
  color:$drk-gray;
  text-align:center;
  position:absolute;
  right:40px;
  top:3px;
  .cdr-circle{
    background:$drk-gray
  }

}
.lang-item {
  display: inline;
}
.cdr-logo{
  margin-top:12px;
}
.cdr-menu{
  margin-top:65px;
}
.cdr-menu ul, .cdr-menu ul li {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
}

.cdr-menu ul li {
  display: inline;
  white-space: nowrap;
  line-height: 56px;
  a{
    color: $drk-gray;
    padding: 20px;
    text-decoration:none;
    text-transform:uppercase;
  }
  a:hover{
    color:$orange

  }

}
.cdr-carousel{
  margin-top:-20px;
}
.cdr-jumbotron{
  background-color:transparent;
  h1{
    font-size:34px;
    font-weight:800;
    text-align:left;
  }
  p{
    text-align:left;
  }
}
.cdr-button{
  background-color:$orange;
  color:#fff;
  border:0;
}
.carousel-caption {
  left: 0;
  right: 45%;
  padding-bottom: 0;
}
.cdr-icon{
  max-width:100%;
}
@media screen and (max-width:768px){
  .cdr-logo{
    max-width:70%;
    margin-top:25px;
  }
  .cdr-carousel{
    display:none;
  }
  .cdr-header{
    border-bottom:1px solid #ddd;
  }
}
.cdr-slider{
  background-image: url("../../dist/images/bima-banner.jpg");
  background-size:cover;
  height:100%;
  width:100%;
  background-position:center center;

}
@media (max-width: 992px) {

  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
  .navbar-collapse{
    background:#fff;
    transition:.4s;
    text-align:center;
    padding-top:35px;
    margin-top:5px;
  }
  .cdr-menu ul, .cdr-menu ul li {
    display: inline-block;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .cdr-menu ul li {
    display: block;
    white-space: nowrap;
    padding: 20px;
    line-height: 56px;
    a{
      color: $drk-gray;
      text-decoration:none;
      text-transform:uppercase;
    }
    a:hover{
      color:$orange

    }

  }
  .cdr-phone, .cdr-lang{
    display:none;
  }
  .navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-bottom:1px solid #ddd;
    background:#fff;
    text-align:center;
    position:relative;
    z-index:100;
    margin-top:21px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .navbar-toggle {
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 40px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  .carousel-caption {
    left: 0;
    right: 45%;
    top:-15%;
  }
}
.carousel-control .fa-angle-right, .carousel-control .icon-next {
  right: 50%;
  margin-right: -10px;
}
.carousel-control .fa-angle-left, .carousel-control .icon-next {
  left: 50%;
  margin-right: -10px;
}
.carousel-control .fa-angle-left, .carousel-control .fa-angle-right, .carousel-control .icon-next, .carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  margin-top: -35px;
  font-size:70px;
  z-index: 5;
  display: inline-block;
}

//AFTER HEADER
.cdr-wrap{
  margin-top:80px;
}
.cdr-table{
  height:100%;
  width:100%;
  display:table;
}
.cdr-cell{
  height:100%;
  width:100%;
  display:table-cell;
  vertical-align:middle;
  text-align:center;
}
.cdr-col-4{
  margin-bottom:30px;
}
.cdr-col-3{
  margin:15px 0;
  height:300px;
}
.cdr-box-col{
  margin:20px 0;
  min-height:100px;
}
.cdr-box-col-1{
  min-height:60px;
}
.cdr-box-header{
  font-weight:600;
  font-size:24px;
  min-height:53px;
}
.cdr-box-h3{
  font-weight:600;
  color:$orange;
  font-size:18px;
}
a.cdr-box-href{
  color:$drk-gray;
  text-decoration:none;
  :hover{
    color:$drk-gray
  }
}
.cdr-box-gray{
  background:$drk-gray;
  min-height:300px;
}
.cdr-color-white{
  color:#fff;
}

.cdr-menu ul li.current-menu-item a {
  color:$orange
}